import React, { Suspense, useEffect } from "react";
import Particle from "./components/Particles/particlesjs";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer/Footer";
import Footerend from "./components/Footerend";
import { CircularProgress } from "@mui/material";

import "./App.css";
import "./output.css";
import "./components/Skills/assets/Card.css";

import Presentation from "./components/Presentation/Presentation";

const Contact = React.lazy(() => import("./components/Contact/Contact"));
const FullWidthTabs = React.lazy(() => import("./components/Portfolio/Portfolio"));
const CardTotal = React.lazy(() => import("./components/Skills/CardTotal"));
const TimelineJob = React.lazy(() => import("./components/Timeline/TimelineJob"));
const TimelineEducation = React.lazy(() => import("./components/Timeline/TimelineEducation"));

const LoadingSpinner = () => (
  <div className="flex w-full justify-center">
    <CircularProgress className="" />
  </div>
);

const App = () => {

  useEffect(() => {
    const splashScreen = document.querySelector(".splashScreen");
    setTimeout(() => {
      splashScreen.classList.add("fade-out-element");
    }, 1000);
  });

  return (
  <div className="App w-full text_font select-none" data-theme="mytheme">
      <div className="splashScreen">
        <LoadingSpinner />
      </div>
      <>
        <Navbar />
        <main className="body text-white">
          <div className="fixed z-0 h-[902px]">
            <Particle />
          </div>
          <section id="homePage" className="homaPageGradient w-full">
            <Suspense fallback={<LoadingSpinner />}>
              <Presentation />
            </Suspense>
          </section>
          <section id="Education" className="contentEducation">
            <Suspense fallback={<LoadingSpinner />}>
              <TimelineEducation />
            </Suspense>
          </section>
          <section id="Experiences" className="">
            <div className="bgExperiences">
              <Suspense fallback={<LoadingSpinner />}>
                <TimelineJob />
              </Suspense>
            </div>
            <div className="bgSkills mt-24 mb-4 h-[100%] lg:mt-[150px] sm:mb-[50px]">
              <Suspense fallback={<LoadingSpinner />}>
                <CardTotal />
              </Suspense>
            </div>
          </section>
          <section id="Portfolio">
            <Suspense fallback={<LoadingSpinner />}>
              <FullWidthTabs />
            </Suspense>
          </section>
          <div id="Contact" className="">
            <Suspense fallback={<LoadingSpinner />}>
              <Contact />
            </Suspense>
          </div>
          <div id="Footer">
            <div id="Footerend" className="w-full">
              <Footerend />
            </div>
          </div>
        </main>
        <Footer />
      </>
  </div>
  );
};

export default App;
