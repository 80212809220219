import React from "react";
import logo1 from "../img/logo1.PNG";

function Footerend() {
  return (
    <footer className="p-6 rounded-lg shadow ">
      <div className="w-full sm:flex sm:items-center sm:justify-between pb-16">
        <a href="https://bld-devs.com/" className="w-full flex justify-center">
          <img src={logo1} className="h-[50px]" alt="Bld Devs Logo" />
        </a>
      </div>
      <hr className=" text-var(--text-color-primary) " />
      <span className="block text-sm text-center text_color_theme">
        © 2019-2022{" "}
        <a href="https://bld-devs.com/" className="hover:underline">
          Bld Devs
        </a>
        . All Rights Reserved.
      </span>
    </footer>
  );
}

export default Footerend;
