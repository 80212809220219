import React from "react";
import { useCallback } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

function Particle() {
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
  }, []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={{
        fullScreen: {
          enable: true,
          zIndex: 0,
        },
        background: {
          color: {
            value: "transparent",
          },
        },
        fpsLimit: 120,
        interactivity: {
          events: {
            onClick: {
              enable: true,
              mode: "push",
            },
            onHover: {
              enable: true,
              mode: "repulse",
            },
            resize: true,
          },
          modes: {
            push: {
              quantity: 4,
            },
            repulse: {
              distance: 50,
              duration: 0.4,
            },
          },
        },
        particles: {
          color: {
            value: ["#01D09E", "#0ECFDE"],
          },
          links: {
            enable: false, // enabling this will make particles linked together
            distance: 200, // maximum distance for linking the particles
          },
          collisions: {
            enable: true,
          },
          move: {
            enable: true, // enabling this will make particles move in the canvas
            speed: { min: 1, max: 1 }, // using a range in speed value will make particles move in a random speed between min/max values, each particles have its own value, it won't change in time by default
          },
          number: {
            density: {
              enable: true,
              area: 200, // this is the area of the particles, it's 200 by default
            },
            value: 10, // this is the number of particles, it's 80 by default
          },
          opacity: {
            value: { min: 0, max: 1 }, // using a different opacity, to have some semitransparent effects
          },
          size: {
            value: { min: 1, max: 3 }, // let's randomize the particles size a bit
          },
          shape: {
            type: "circle",
          },
          speed: {
            value: { min: 1, max: 5 }, // let's randomize the particles speed a bit
          },
        },
        detectRetina: true,
      }}
    />
  );
}

export default Particle;
