import * as React from "react";
import Box from "@mui/material/Box";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import WorkHistoryRoundedIcon from "@mui/icons-material/WorkHistoryRounded";
import PermContactCalendarRoundedIcon from "@mui/icons-material/PermContactCalendarRounded";
import AutoStoriesRoundedIcon from "@mui/icons-material/AutoStoriesRounded";
import { Link } from "react-scroll";
import "./Footer.css";

function Footer() {
  return (
    <Box className="Footer w-full flex justify-center z-[1000]">
      <div className="sub-footer w-[336px] rounded-full backdrop-blur-md h-[54px] bg-var(--navbar-color) shadow_box_footer">
        <ul className="w-full h-full flex flex-cols-5 justify-between items-center ">
          <li>
            <Link
              activeClass="active"
              className="h-full flex items-center p-4 tabs22"
              to="homePage"
              spy={true}
              smooth={true}
              duration={200}
            >
              <HomeRoundedIcon sx={{ color: "var(--text-color-primary)" }} />
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              className="h-full flex items-center p-4 tabs22"
              to="Education"
              spy={true}
              smooth={true}
              duration={200}
            >
              <SchoolRoundedIcon sx={{ color: "var(--text-color-primary)" }} />
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              className="h-full flex items-center p-4 tabs22"
              to="Experiences"
              spy={true}
              smooth={true}
              duration={200}
            >
              <WorkHistoryRoundedIcon
                sx={{ color: "var(--text-color-primary)" }}
              />
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              className="h-full flex items-center p-4 tabs22"
              to="Portfolio"
              spy={true}
              smooth={true}
              duration={200}
            >
              <AutoStoriesRoundedIcon
                sx={{ color: "var(--text-color-primary)" }}
              />
            </Link>
          </li>
          <li>
            <Link
              activeClass="active"
              className="h-full flex items-center p-4 tabs22"
              to="Contact"
              spy={true}
              smooth={true}
              duration={200}
            >
              <PermContactCalendarRoundedIcon
                sx={{ color: "var(--text-color-primary)" }}
              />
            </Link>
          </li>
        </ul>
      </div>
    </Box>
  );
}

export default Footer;
