import React, { Fragment } from 'react';
import "./Presentation.css";
import img from "./bld-alex.png";
import { Link } from 'react-scroll'
// import Download from "./Download";

export default function Presentation() {


  return (
    <Fragment>
      <div className='w-full flex flex-col items-center py-6 px-10'>
      
        <div>
          <h1 className="text-[25px] sm:text-[30px] md:text-[35px] text_color_theme font-bold pt-4 name-focus-in">Alexandre BLIND</h1>
        </div>
        <div className='triangle-focus-in'>
          <div className="mask mask-triangle-2 flex w-[200px] sm:w-[290px] md:w-[325px] h-[100%] changementCouleurPresentation px-6 pt-4 ">
            <img className="imagehover" src={img} alt="Alexandre Blind" />
          </div>
        </div>
        <div>
          <h1 className="text-[34px] md:text-[40px] lg:text-[50px] text_color_theme font-bold pt-4 job-focus-in">Web developper</h1>
          <p className="text-lg lg:text-[20px] text-center subtitle font-bold pb-4 animNeon">Django / React.js</p>
        </div>
        <div className='flex flex-col items-center mt-20 sm:mt-10 md:mt-24 cat-focus-in'>
          <Link className="rounded-full clickhere bounce2 " to="Education" smooth={true} duration={200} >
              <svg className="" width="70" height="70" viewBox='0 0 24 24' xmlns="http://www.w3.org/2000/svg">

                <path fill="var(--secondary-color)" d="M17.29 5.71a.9959.9959 0 0 0-1.41 0L12 9.58 8.11 5.7a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.39-.38.39-1.01 0-1.4z"/>

                <path fill="var(--primary-color)" d="M17.29 12.3a.9959.9959 0 0 0-1.41 0L12 16.17l-3.88-3.88a.9959.9959 0 0 0-1.41 0c-.39.39-.39 1.02 0 1.41l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.38-.38.38-1.01-.01-1.4z"/>
              </svg>
              
          </Link>

          <p className="text-base text_color_theme  py-2">Learn more about me</p>
        </div>
        <div>
          {/* <Download /> */}
        </div>
      
      </div>
    </Fragment>
  );
}